<template>

  <Row v-if="ready" :class="row.even() ? 'rowEven' : 'rowOdd'">
    <Column>
      <Box>
        
        <Row>
          <Column :width="1">
            <Label> </Label>
            <Value> {{ item.index() + 1 }} </Value>
          </Column>
          
          <Column :width="10">
            <Box>
              <KeyValue 
                :title="ChecklistItem.Fields.Name" 
                :placeholder="MC.CheckList.Add.QuestionNameLabel.value()" 
                :defaultValue="item.name()"
                :mandatory="true"
                :required="showMissingRequirements"
                :hint="MC.CheckList.Add.QuestionNameHint.value()" 
                v-on:value="withValue" 
                />
            </Box>
          </Column>
            
          <Column :width="3">
            <Box>
              <KeyList
                :title="ChecklistItem.Fields.Type" 
                :placeholder="MC.CheckList.Add.ChecklistItemTypeLabel.value()" 
                :hint="MC.CheckList.Add.ChecklistItemTypeHint.value()"
                :defaultValue="item.type()" 
                :mandatory="true"
                :options="Contents.ChecklistItemTypes()"
                v-on:value="withValue" />
            </Box>
          </Column>
          <Column :width="1" class="rc-text-right" :showBorder="false">
            <Box>
              <Row>
                <Column>
                  <img v-on:click="withButtonPressed()" 
                  alt="Remove Question" style="cursor: pointer;" 
                  src="@/assets/2023/ClearSearch.png" width="15px" height="15px" />
                </Column>
              </Row>
              <Row>
                <Column>
                  <img v-if="showUp" v-on:click="doUp" 
                  alt="Move Question Up" style="cursor: pointer;" 
                  src="@/assets/2023/MoveUp.png" width="15px" height="15px" />
                </Column>
              </Row>
              <Row>
                <Column>
                  <img v-if="showDown" v-on:click="doDown" 
                  alt="Move Question Down" style="cursor: pointer;" 
                  src="@/assets/2023/MoveDown.png" width="15px" height="15px" />
                </Column>
              </Row>
            </Box>
          </Column>
       </Row>
       
       <Row v-if="item.isTypeList() && !item.isTypeBool()">
        <Column :width="3">
        </Column>
        <Column :width="12">
          <Box :showBorder="true">
            <KeyCheck v-if="ready" :class="css" 
              :title="ChecklistItem.Fields.MultiSelect" 
              :placeholder="MC.CheckList.Add.ChecklistItemMultiSelectLabel.value()" 
              :defaultValue="item.multiSelect()"
              :mandatory="false"
              v-on:value="withValue" 
              />
          </Box>
        </Column>
       </Row>
       
       <Row 
          v-for="(child, itemOptionIndex) in children" 
          :key="itemOptionIndex" >
          
            <Column :width="3">
            </Column>
            <ChildCellFactory
              :width="12" 
              :rowData="child.data" 
              :showRemove="item.isTypeList()"
              :showMissingRequirements="showMissingRequirements"
              v-on:withValue="withChildValue"
              v-on:withButtonPressed="withChildButtonPressed" />

        </Row>
        
     </Box>  
    </Column>
  </Row>
  
  
</template>

<script>

import { mapGetters } from 'vuex'

import MC from "@/domain/session/MC.js";

import ContentUtils from '@/utils/ContentUtils.js';

import Box from "@/portals/shared/library/box/Box.vue"; 
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Label from "@/portals/shared/library/label/Label.vue";
import Value from "@/portals/shared/library/value/Value.vue";

import KeyValue from '@/components/input/KeyValue.vue';
import KeyList from '@/components/input/KeyList.vue';
import KeyCheck from '@/components/input/KeyCheck.vue';

import ChecklistItemUpdateRow from "./ChecklistItemUpdateRow.js";

import ChecklistItem from '@/domain/model/checklist/item/CheckListItem.js';


export default {
  name: 'checklist-item-update-cell',
  components: {
    Row, Column, Box,
    Label, Value,
    KeyValue, KeyList, KeyCheck,
    ChildCellFactory: () => import("@/portals/shared/cell/CellFactory.vue")
  },
  props: {
    css: { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      MC : new MC(),
      Contents: ContentUtils,
      ChecklistItem: ChecklistItem,
      row: new ChecklistItemUpdateRow(),
      item: new ChecklistItem(this.domain,{}),
      children : [],
      index: 0,
      maxIndex: 0,
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    showUp: function() {
      return this.index > 0;
    },
    showDown: function() {
      return this.index < this.maxIndex - 1;
    }
  },
  mounted: function () {
    this.row.data = this.data;
    this.item = new ChecklistItem(this.domain, this.row.item());
    this.children = this.row.children();
    this.maxIndex = this.row.maxIndex();
    this.index = this.row.index();
    this.ready = true;
  },
  methods: {
    doDown: function() {
      var data = {};
      data['id'] = this.item.id();
      data['object'] = this.row.object();
      data['field'] = "downQuestionButton"
      this.$emit('click', data);
      this.$emit('withButtonPressed', data);
      
    },
    doUp: function() {
      var data = {};
      data['id'] = this.item.id();
      data['object'] = this.row.object();
      data['field'] = "upQuestionButton"
      this.$emit('click', data);
      this.$emit('withButtonPressed', data);
    },
    doRemoveAction: function() {
      //
    },
    withValue: function(kvp) {
      kvp['id'] = this.item.id();
      kvp['object'] = ChecklistItem.MODEL_NAME;
      this.$emit('withValue', kvp);
    },
    withButtonPressed: function() {
      var data = {};
      data['id'] = this.item.id();
      data['object'] = this.row.object();
      data['field'] = "removeQuestionButton"
      this.$emit('click', data);
      this.$emit('withButtonPressed', data);
    },
    withChildValue: function(kvp) {
      kvp['parentId'] = this.item.id();
      this.$emit('withValue', kvp);
    },
    withChildButtonPressed: function(data) {
      data['parentId'] = this.item.id();
      this.$emit('withButtonPressed', data);
    },
  },
}
</script>






