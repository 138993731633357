<template>
  <div class="row">
    <div v-if="left" :class="leftPadding"> </div>
    <div :class="middle" ref="integer">
      <div v-if="isTitleShowing" class="rc-font-small rc-text-key">{{ placeholderText() }}</div>
      <div v-else class="rc-font-small">
        <div v-if="placeholder != null" > 
          <br> 
        </div>
      </div>
      <input class="rc-input-field rc-input-width rc-text-value"
        :placeholder="placeholderText()" 
        type="number" 
        v-model="value"
        v-on:keyup.enter="enter"
        v-on:input="$emit('value', {'key':title, 'value':value})"
        style="height: 30px; padding-left: 20px; padding-right: 20px;"
        />
      <div v-if="!shouldShowRequired" class="rc-font-small rc-text-value">{{ hint }} <br /></div>
      <div v-if="shouldShowRequired" class="rc-font-small rc-background-required">A value for {{ C.get(placeholder) }} is required</div>
    </div>
    <div v-if="right" :class="rightPadding"> </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import StringUtils from '@/utils/StringUtils.js';
import Catelog from "@/domain/session/CanadianEnglish.js";
import Catalog from '@/domain/session/Catalog.js';

export default {
  name: 'key-integer',
  components: {
  },
  props: {
    title:  String,
    hint:   String,
    defaultValue: String,
    placeholder:  String,
    left:   {type: Number, default: 0},
    right:  {type: Number, default: 0},
    mandatory:  {type: Boolean, default: false},
    required:   {type: Boolean, default: false},
  },
  data() {
    return {
      value: this.defaultValue,
      isTitleShowing: false,
      Catelog: new Catelog(),
      C: new Catalog(),
    };
  },
  computed: {
    ...mapGetters([
      'domain',
    ]),
    shouldShowRequired: function() {
      if (this.required) {
        if (this.mandatory) {
          if (StringUtils.isEmpty(this.value)) {
            return true;
          }
        }
      }
      return false;
    },
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
  },
  watch: {
    value() {
      this.validate();
    },
    required() {
      if (this.required) {
        this.$refs.integer.scrollIntoView();
      }
    },
  },
  mounted: function() {
    this.validate();
  },
  methods: {
    placeholderText: function() {
      if (this.mandatory) {
        return this.Catelog.get(this.placeholder) + "*";
      }
      return this.Catelog.get(this.placeholder);
    },
    validate: function() {
      if (this.value != null && this.value.trim().length > 0) {
        this.isTitleShowing = true;
      } else {
        this.isTitleShowing = false;
      }
    },
    enter: function() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(this.emit, 500);
    },
    
    emit: function() {
      clearInterval(this.timer);
      this.$emit('enter', this.title);
    }
  },

}
</script>
