<template>

  <Row v-if="ready" :showBorder="false" :class="row.even() ? 'rowEven' : 'rowOdd'">
    
    <Column :width="15">
      <Box>
        <KeyList
          :title="Vehicle.FIELDS._CATEGORY" 
          :placeholder="MC.Vehicle.Add.VehicleType.value()" 
          :defaultValue="row.defaultValue()"
          :mandatory="row.mandatoryValue()"
          :required="showMissingRequirements"
          :options="categories"
          v-on:value="withValue" 
        />
      </Box>
    </Column>
  </Row>
  
  
</template>

<script>

import { mapGetters } from 'vuex'

import MC from "@/domain/session/MC.js";

import ContentUtils from '@/utils/ContentUtils.js';

import Box from "@/portals/shared/library/box/Box.vue"; 
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import KeyList from '@/components/input/KeyList.vue';

import Vehicle from '@/domain/model/vehicle/Vehicle.js';
import VehicleTypeUpdateRow from "./VehicleTypeUpdateRow.js";

export default {
  name: 'vehicle-type-update-cell',
  components: {
    Row, Column, Box,
    KeyList,
    /* ChildCellFactory: () => import("@/portals/shared/cell/CellFactory.vue") */
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      MC : new MC(),
      Contents: ContentUtils,
      row: new VehicleTypeUpdateRow(),
      categories: [],
      
      Vehicle: Vehicle,
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  mounted: function () {
    this.row.data = this.data;
    var defaultValue = this.row.defaultValue();
    if (defaultValue) {
      //
    }
    this.categories = ContentUtils.CATEGORIES();
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      kvp['id'] = this.row.id();
      kvp['object'] = Vehicle.MODEL_NAME;
      kvp['field'] = this.row.field();
      this.$emit('withValue', kvp);
    },
    withButtonPressed: function() {
      var data = {};
      data['id'] = this.row.id();
      data['object'] = Vehicle.MODEL_NAME;
      data['field'] = this.row.field();
      this.$emit('click', data);
      this.$emit('withButtonPressed', data);
    }
  },
}
</script>






