
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class RowDisplayRow extends RcTableViewRow {

  static Id = "RowDisplayRow";
  static Border = "border";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, RowDisplayRow.Id);
    this.withBorder(false);
    this.withChildren([]);
  }

  withBorder(border) {
    this.put(RowDisplayRow.Border, border);
    return this;
  }
  
  border() {
    return this.get(RowDisplayRow.Border);
  }
  
}