
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class StringListUpdateRow extends RcTableViewRow {

  static Id = "StringListUpdateRow";
  static Options = "options";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, StringListUpdateRow.Id);
    this.withOptions([]);
  }

  withOptions(options) {
    this.put(StringListUpdateRow.Options, options);
    return this;
  }

  options() {
    return this.get(StringListUpdateRow.Options);
  }
  
}