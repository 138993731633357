
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class BoxDisplayRow extends RcTableViewRow {

  static Id = "BoxDisplayRow";
  static Border = "border";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, BoxDisplayRow.Id);
    this.withBorder(false);
    this.withChildren([]);
  }

  withBorder(border) {
    this.put(BoxDisplayRow.Border, border);
    return this;
  }
  
  border() {
    return this.get(BoxDisplayRow.Border);
  }
  
}