<template>

  <Row v-if="ready" :showBorder="true" :class="row.even() ? 'rowEven' : 'rowOdd'">
    
    <Column :width="1">
      <Label> </Label>
      <Value> {{ option.index() + 1 }} </Value>
    </Column>
    
    <Column :width="10">
      <Box>
        <KeyValue
          :title="ChecklistItemOption.Fields.Name" 
          :placeholder="MC.CheckList.Add.OptionTextLabel.value()" 
          :defaultValue="option.name()"
          :hint="MC.CheckList.Add.OptionTextHint.value()" 
          :mandatory="true"
          :required="showMissingRequirements"
          v-on:value="withValue" 
        />
      </Box>
    </Column>
    
    <Column :width="3">
      <Box>
        <KeyList
          :title="ChecklistItemOption.Fields.Category" 
          :placeholder="MC.CheckList.Add.ChecklistItemOptionCategoryLabel.value()" 
          :hint="MC.CheckList.Add.ChecklistItemOptionCategoryHint.value()"
          :defaultValue="option.category()" 
          :mandatory="true"
          :options="Contents.ChecklistItemOptionCategories()"
          v-on:value="withValue" />
      </Box>
    </Column>
    
    <Column :width="1" v-if="showRemove" class="rc-text-right">
      <Box>
        <Row>
          <Column>
              <img 
              v-if="row.showRemove()"
              v-on:click="withButtonPressed()" 
              style="cursor: pointer;" 
              src="@/assets/2023/ClearSearch.png" 
              width="15px" 
              height="15px" />
          </Column>
        </Row>
      </Box>
    </Column>
    
    <Column :width="1" v-else>
    </Column>
    
  </Row>
  
  
</template>

<script>

import { mapGetters } from 'vuex'

import MC from "@/domain/session/MC.js";

import ContentUtils from '@/utils/ContentUtils.js';

import Box from "@/portals/shared/library/box/Box.vue"; 
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Label from "@/portals/shared/library/label/Label.vue";
import Value from "@/portals/shared/library/value/Value.vue";

import KeyValue from '@/components/input/KeyValue.vue';
import KeyList from '@/components/input/KeyList.vue';

import ChecklistItemOptionUpdateRow from "./ChecklistItemOptionUpdateRow.js";
import ChecklistItemOption from '@/domain/model/checklist/item/option/CheckListItemOption.js';

export default {
  name: 'checklist-item-option-update-cell',
  components: {
    Row, Column, Box,
    Label, Value,
    KeyValue, KeyList,
    /* ChildCellFactory: () => import("@/portals/shared/cell/CellFactory.vue") */
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    showRemove: { type: Boolean, default: true },
    data: null,
  },
  data() {
    return {
      ready: false,
      MC : new MC(),
      Contents: ContentUtils,
      ChecklistItemOption: ChecklistItemOption,
      row: new ChecklistItemOptionUpdateRow(),
      option: new ChecklistItemOption(this.domain,{}),
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  mounted: function () {
    this.row.data = this.data;
    this.option = new ChecklistItemOption(this.domain, this.row.option());
    this.ready = true;
  },
  methods: {
    doRemoveAction: function() {
      //
    },
    withValue: function(kvp) {
      kvp['id'] = this.option.id();
      kvp['object'] = ChecklistItemOption.MODEL_NAME;
      this.$emit('withValue', kvp);
    },
    withButtonPressed: function() {
      var data = {};
      data['id'] = this.option.id();
      data['object'] = this.row.object();
      data['field'] = "removeOptionButton"
      this.$emit('click', data);
      this.$emit('withButtonPressed', data);
    }
  },
}
</script>






