<template>

  <KeyText v-if="ready" :class="css" 
    :title="row.field()" 
    :placeholder="row.name()" 
    :defaultValue="row.defaultValue()"
    :hint="row.hint()" 
    v-on:value="withValue" 
    />
  
</template>

<script>

import { mapGetters } from 'vuex'

import TextAreaUpdateRow from "./TextAreaUpdateRow.js";
import KeyText from '@/components/input/KeyTextArea.vue';

export default {
  name: 'text-area-update-cell',
  components: {
    KeyText,
  },
  props: {
    css:    { type: String, default: "" },
    data: null,
  },
  data() {
    return {
      ready: false,
      row: new TextAreaUpdateRow(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  mounted: function () {
    this.row.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>