
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class TextAreaUpdateRow extends RcTableViewRow {

  static Id = "TextAreaUpdateRow";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, TextAreaUpdateRow.Id);
  }

}