
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class StatusDisplayRow extends RcTableViewRow {

  static Id = "StatusDisplayRow";
      
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, StatusDisplayRow.Id);
  }
  
  
  withLabel(label) {
    this.withName(label).done();
    return this;
  }
  
  label() {
    return this.name();
  }
  
}