
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ButtonDisplayRow extends RcTableViewRow {

  static Id = "ButtonDisplayRow";
  static Background = "background";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ButtonDisplayRow.Id);
  }

  withBackgroundPrimaryAction() {
    this.put("background", "go");
    return this;
  }
  
  withBackgroundNoAction() {
    this.put("background", "no");
    return this;
  }

  backgroundAction() {
    return this.get("background");
  }
  
  isBackgroundPrimaryAction() {
    return this.backgroundAction() === "go";
  }

  isBackgroundNoAction() {
    return this.backgroundAction() === "no";
  }
}